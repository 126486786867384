import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import config from '../config'
import Sentry from '../utils/sentry'

const initialState = {
  isLoggedIn: false,
  error: null,
  codeSent: false,
  passwordReset: false,
  cognito: {},
  account: {
    isAdmin: false,
    creditApproved: [],
    creditLimit: null,
    creditDays: null,
    _id: '',
    username: '',
    name: '',
    emailAddress: '',
    stripeCustomerID: '',
    cognitoIdentityId: '',
    last4: null,
    venues: [],
    supplierAdmin: false,
    totalPages: null,
    paginationVenuesLoading: false,
  },
  isLoading: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn
      state.cognito = action.payload.cognito
      state.account = action.payload.account
    },
    setAccount: (state, action) => {
      state.account = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    resetPasswordCodeSent: state => {
      state.codeSent = true
    },
    resetPasswordSuccess: state => {
      state.codeSent = true
      state.passwordReset = true
    },
    setVenues: (state, action) => {
      state.account.venues = action.payload
    },
    setTotalPages: (state, action) => {
      state.account.totalPages = action.payload
    },
    setPaginationVenuesLoading: (state, action) => {
      state.account.paginationVenuesLoading = action.payload
    },
    setOneVenue: (state, action) => {
      const index = state.account.venues.findIndex(
        venue => venue._id === action.payload._id,
      )
      if (index !== -1) state.account.venues[index] = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  setUser,
  setAccount,
  setError,
  resetPasswordCodeSent,
  resetPasswordSuccess,
  setIsLoading,
  reset,
  setVenues,
  setOneVenue,
  setTotalPages,
  setPaginationVenuesLoading,
} = authSlice.actions

export const register = data => async dispatch => {
  dispatch(setIsLoading(true))
  try {
    await Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        name: data.name,
      },
    })
    dispatch(setIsLoading(false))
    window.location.href = '/login'
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    dispatch(setError(error))
    dispatch(setIsLoading(false))
    return false
  }
}

export const resetEmailAddress = address => async dispatch => {
  try {
    await Auth.forgotPassword(address)
    dispatch(resetPasswordCodeSent())
  } catch (error) {
    const resetold = async () => {
      if (error.message == 'Username/client id combination not found.') {
        const res = await resetEmailAddressOldPool(address, dispatch)
        if (res.success) {
          dispatch(resetPasswordCodeSent())
        }
      }
    }
    resetold()
  }
}

const resetEmailAddressOldPool = async (address, dispatch) => {
  return new Promise(resolve => {
    const reset = async () => {
      try {
        Auth.configure({
          mandatorySignIn: true,
          region: config.cognito.OLD_REGION,
          userPoolId: config.cognito.USER_POOL_OLD_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_OLD_ID,
          identityPoolId: config.cognito.IDENTITY_POOL_OLD_ID,
        })
        const reset = await Auth.forgotPassword(address)
        resolve({ success: true, data: reset })
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        dispatch(setIsLoading(false))
        dispatch(setError(error.message))
        resolve({ success: false, error })
      } finally {
        Auth.configure({
          mandatorySignIn: true,
          region: config.cognito.REGION,
          userPoolId: config.cognito.USER_POOL_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_ID,
          identityPoolId: config.cognito.IDENTITY_POOL_ID,
        })
      }
    }
    reset()
  })
}

export const submitCodeAndPassword = data => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    await Auth.forgotPasswordSubmit(data.email, data.code, data.password)
    dispatch(resetPasswordSuccess())
    alert('Your password has been reset')
    window.location.href = '/login'
  } catch (error) {
    const setold = async () => {
      if (error.message == 'Username/client id combination not found.') {
        const res = await submitCodeAndPasswordOldPool(data, dispatch)
        if (res.success) {
          dispatch(resetPasswordSuccess())
          alert('Your password has been reset')
          window.location.href = '/login'
        }
      } else {
        dispatch(setIsLoading(false))
        dispatch(setError(error.message))
      }
    }
    setold()
  }
}

const submitCodeAndPasswordOldPool = async (data, dispatch) => {
  return new Promise(resolve => {
    const reset = async () => {
      try {
        Auth.configure({
          mandatorySignIn: true,
          region: config.cognito.OLD_REGION,
          userPoolId: config.cognito.USER_POOL_OLD_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_OLD_ID,
          identityPoolId: config.cognito.IDENTITY_POOL_OLD_ID,
        })
        const reset = await Auth.forgotPasswordSubmit(
          data.email,
          data.code,
          data.password,
        )
        resolve({ success: true, data: reset })
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        dispatch(setIsLoading(false))
        dispatch(setError(error.message))
        resolve({ success: false, error })
      } finally {
        Auth.configure({
          mandatorySignIn: true,
          region: config.cognito.REGION,
          userPoolId: config.cognito.USER_POOL_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_ID,
          identityPoolId: config.cognito.IDENTITY_POOL_ID,
        })
      }
    }
    reset()
  })
}

export const login = data => async dispatch => {
  const migrateUser = async cognitoId => {
    console.log('cognitoId', cognitoId)
    try {
      const response = await API.post('api', `user/migration`, {
        body: {
          username: data.email,
          password: data.password,
          cognitoId,
        },
      })

      if (response.success) {
        await loginUserNewPool()
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      dispatch(setIsLoading(false))
      dispatch(setError(err.message))
    }
  }

  const loginUserNewPool = async () => {
    try {
      await Auth.signIn(data.email, data.password)
      const session = await Auth.currentAuthenticatedUser()
      const account = await API.get('api', `account/${session.attributes.sub}`)

      dispatch(
        setUser({
          isLoggedIn: true,
          cognito: {
            name: session.attributes['Name'],
            username: session.attributes.sub,
            token: session.signInUserSession.idToken.jwtToken,
          },
          account: account,
        }),
      )

      dispatch(setIsLoading(false))
      account.supplierAdmin
        ? (window.location.href = '/supplier')
        : (window.location.href = `/venues`)
    } catch (error) {
      const dsd = async () => {
        console.error(error)
        Sentry.captureException(error)
        if (error?.message === 'User does not exist.') {
          try {
            const result = await loginUserOldPool()
            console.log('result loginUserOldPool', result)
            if (result.success) {
              await migrateUser(result?.authdata?.username)
            }
          } catch (error) {
            dispatch(setIsLoading(false))
            dispatch(setError(error.message))
          }
        } else {
          console.log('am here', error)
          dispatch(setIsLoading(false))
          dispatch(setError(error.message))
        }
      }
      dsd()
    }
  }

  const loginUserOldPool = async () => {
    return new Promise(resolve => {
      const ds = async () => {
        try {
          Auth.configure({
            mandatorySignIn: true,
            region: config.cognito.OLD_REGION,
            userPoolId: config.cognito.USER_POOL_OLD_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_OLD_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_OLD_ID,
          })
          const authdata = await Auth.signIn(data.email, data.password)
          resolve({ success: true, authdata })
        } catch (error) {
          console.error(error)
          Sentry.captureException(error)
          dispatch(setIsLoading(false))
          dispatch(setError(error.message))
          resolve({ success: false, error })
        } finally {
          Auth.configure({
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
          })
        }
      }
      ds()
    })
  }

  try {
    dispatch(setIsLoading(true))
    await loginUserNewPool()
  } catch (error) {
    dispatch(setIsLoading(false))
    dispatch(setError(error.message))
    console.error(error)
    Sentry.captureException(error)
  }
}

export const getVenues = data => async dispatch => {
  dispatch(setPaginationVenuesLoading(true))
  try {
    const { venues, pagination } = await API.get(
      'api',
      `venues/${data?.username}?page=${data?.page}&limit=${data?.limit}`,
    )
    dispatch(setVenues(venues))
    dispatch(setTotalPages(pagination.totalPages))

    dispatch(setPaginationVenuesLoading(false))
  } catch (error) {
    console.log(error)
    Sentry.captureException(error)
    dispatch(setPaginationVenuesLoading(false))
  }
}

export const searchVenues = data => async dispatch => {
  dispatch(setPaginationVenuesLoading(true))
  try {
    const { venues, pagination } = await API.get(
      'api',
      `venues/${data?.username}?title=${data?.title}&page=${data?.page}&limit=${data?.limit}`,
    )
    dispatch(setVenues(venues))
    dispatch(setTotalPages(pagination.totalPages))
    dispatch(setPaginationVenuesLoading(false))
  } catch (error) {
    console.log(error)
    Sentry.captureException(error)
    dispatch(setPaginationVenuesLoading(false))
  }
}

export default authSlice
