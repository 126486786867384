import { dpr, format, quality } from '@cloudinary/base/actions/delivery'
import { fill } from '@cloudinary/base/actions/resize'
import { auto } from '@cloudinary/base/qualifiers/format'
import { auto as qAuto } from '@cloudinary/base/qualifiers/quality'
import { AdvancedImage } from '@cloudinary/react'
import React from 'react'
import cld from 'src/utils/cloudinaryInstance'
import styled from 'styled-components'
import ComingImg from 'src/assets/images/imageComingGrn.png'

const ProductImage = ({ imageId }) => {
  if (!imageId) return <ComingImage src={ComingImg} alt={''} />
  const image = cld.image(imageId)
  const isRemote =
    imageId.startsWith('https://') || imageId.startsWith('http://')
  if (isRemote) {
    // image.setStorageType('fetch')
  }
  image
    .resize(fill().width(60).height(60))
    .delivery(dpr(window.devicePixelRatio))
    .delivery(format(auto()))
    .delivery(quality(qAuto()))
  return <Image cldImg={image} />
}

const Image = styled(AdvancedImage)`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colours.bgGrey};
`

const ComingImage = styled.img`
  width: 60px;
  height: 60px;
`

export default ProductImage
