import { Button, Modal, Table, Tag, Select, Card } from 'antd'
import Dinero from 'dinero.js'
import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Title from 'src/branding/Title'
import FadeIn from 'src/components/styled/FadeIn'
import {
  AssignEmailToVenue,
  editAccount,
  fetchAccounts,
  fetchAccountVenues,
} from 'src/features/AccountsSlice'
import { fetchAllSuppliers } from 'src/features/SupplierSlice'
import EditAccountModal from 'src/screens/accounts/EditAccountModal'
import styled from 'styled-components/macro'
import { useWindowWidth } from 'src/utils/useWindowWidth'
import { ThemeContext } from 'styled-components/macro'
import LoadingButton from 'src/components/styled/LoadingButton'

const Accounts = () => {
  const dispatch = useDispatch()
  const accounts = useSelector(state => state.accounts.accounts)
  const isLoading = useSelector(state => state.accounts.isLoading)
  const Allvenues = useSelector(state => state.accounts.accountVenues)
  const suppliers = useSelector(state => state.suppliers.suppliers)

  const [editingAccount, setEditingAccount] = useState(null)
  const username = useSelector(state => state.auth.cognito.username)
  const windowWidth = useWindowWidth()
  const theme = useContext(ThemeContext)
  const isMobile = windowWidth <= theme.breakpoints.lg
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [selectedVenueId, setSelectedVenueId] = useState(null)
  const [loading, setLoading] = useState(false)

  const { Option } = Select

  useEffect(() => {
    dispatch(fetchAccounts())
    dispatch(fetchAccountVenues({ username }))
    if (suppliers.length === 0) dispatch(fetchAllSuppliers())
  }, [dispatch])

  return (
    <Wrapper>
      <Title>Accounts</Title>
      <Card style={{ marginBottom: 25, marginTop: 10 }}>
        <DivContainer gutter={16}>
          <DivFlex>
            <Select
              placeholder='Select a Venue'
              style={{ width: isMobile ? '100%' : 250 }}
              showSearch={true}
              onChange={e => {
                setSelectedVenueId(e)
              }}
            >
              {Allvenues.map(venue => {
                return (
                  <Option key={venue._id} value={venue._id}>
                    {venue.title}
                  </Option>
                )
              })}
            </Select>
            <Select
              placeholder='Select an Email'
              style={{ width: isMobile ? '100%' : 250 }}
              showSearch={true}
              onChange={e => {
                setSelectedEmail(e)
              }}
            >
              {accounts.map(account => {
                return (
                  <Option key={account._id} value={account.emailAddress}>
                    {account.emailAddress}
                  </Option>
                )
              })}
            </Select>
            <LoadingButton
              style={{
                marginLeft: 0,
                marginTop: isMobile ? 15 : 0,
                marginBottom: isMobile ? 30 : 0,
              }}
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await AssignEmailToVenue({
                  email: selectedEmail,
                  venueId: selectedVenueId,
                })
                setLoading(false)
                window.location.reload()
              }}
            >
              Assign Email To Venue
            </LoadingButton>
          </DivFlex>
        </DivContainer>
      </Card>

      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        dataSource={(accounts || []).map(account => ({
          ...account,
          key: account._id,
        }))}
        loading={isLoading}
        pagination={false}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'restrictShrink',
          },
          {
            title: 'Email address',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            className: 'restrictShrink',
          },
          {
            title: 'Venue owned',
            dataIndex: '_id',
            key: 'venue',
            className: 'restrictShrink',
            render: accountId =>
              Allvenues.find(venue => venue.account === accountId)?.title,
          },
          {
            title: 'Credit limit',
            dataIndex: 'creditLimit',
            key: 'creditLimit',
            className: 'restrictShrink',
            render: creditLimit =>
              Dinero({
                amount: creditLimit || 0,
                currency: 'GBP',
              }).toFormat('$0,0.00'),
          },
          {
            title: 'Days credit after statement',
            dataIndex: 'creditDays',
            key: 'creditDays',
            className: 'restrictShrink',
          },
          {
            title: 'Credit approvals',
            dataIndex: 'creditApproved',
            key: 'creditApproved',
            className: 'restrictShrink',
            render: approvals => (
              <Tag color={approvals?.length ? 'green' : 'red'}>
                {approvals?.length
                  ? `${approvals.length} Approval${
                      approvals.length === 1 ? '' : 's'
                    }`
                  : 'No approvals'}
              </Tag>
            ),
          },
          {
            title: 'Action',
            key: 'action',
            className: 'restrictShrink',
            render: (text, account) => (
              <>
                <StyledButton
                  type='dashed'
                  onClick={() => {
                    setEditingAccount(account)
                  }}
                >
                  Edit
                </StyledButton>
                &nbsp;
              </>
            ),
          },
        ]}
      />

      <Modal
        title='Edit account'
        visible={editingAccount !== null}
        onOk={() => {
          dispatch(
            editAccount({
              ...editingAccount,
            }),
          )
          setEditingAccount(null)
        }}
        onCancel={() => {
          setEditingAccount(null)
        }}
      >
        {editingAccount && (
          <EditAccountModal
            allVenues={Allvenues}
            account={editingAccount}
            suppliers={suppliers}
            setEditingAccount={account => {
              setEditingAccount(account)
            }}
          />
        )}
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)``

const StyledButton = styled(Button)`
  margin: 3px;
`

const DivContainer = styled.div`
  display: flex;
  width: 100%;
`
const DivFlex = styled.div`
  display: flex;
  gap: 20px;
  width: 60%;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'flex-start')};
  padding-top: 8px;
  flex-wrap: wrap;
`

export default Accounts
