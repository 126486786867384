import { Button, Card, Form, Input, Popconfirm, Select } from 'antd'
import React, { useState } from 'react'
import { isEmail, ReactMultiEmail } from 'react-multi-email'
import styled from 'styled-components/macro'

const SupplierCard = ({ saveSupplier, form }) => {
  const emptySupplier = {
    title: '',
    secondTitle: '',
    emailAddress: '',
    accountNumber: '',
    vendorCode: '',
    minOrderValue: null,
    deliveryDays: [],
    phoneNumber: '',
    orderCutOffTime: '',
    logoUrl: '',
    secondEmailAddresses: [],
  }
  const [supplier, setSupplier] = useState(emptySupplier)
  const { getFieldDecorator } = form

  const handleSubmit = () => {
    form.validateFields(error => {
      if (!error) {
        saveSupplier(supplier)
        setSupplier(emptySupplier)
        form.resetFields()
      }
    })
  }

  return (
    <Form>
      <CardComp>
        <h4>Create supplier</h4>
        <FormItem>
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: 'Please input the title',
              },
            ],
          })(
            <InputComp
              value={supplier.title}
              onChange={e => {
                setSupplier({ ...supplier, title: e.target.value })
              }}
              addonBefore={'Title'}
              placeholder='Title'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('secondTitle', {
            rules: [
              {
                required: true,
                message: 'Please input the second title',
              },
            ],
          })(
            <InputComp
              value={supplier.secondTitle}
              onChange={e => {
                setSupplier({ ...supplier, secondTitle: e.target.value })
              }}
              addonBefore={'Second title'}
              placeholder='Second title'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Please input de email address',
              },
              {
                type: 'email',
                message: 'The input is not valid email',
              },
            ],
          })(
            <InputComp
              value={supplier.emailAddress}
              onChange={e => {
                setSupplier({ ...supplier, emailAddress: e.target.value })
              }}
              addonBefore={'Order email address'}
              placeholder='Order email address'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('secondEmail', {
            rules: [
              {
                required: false,
                message: 'Please input the email address',
              },
              {
                type: 'email',
                message: 'The input is not valid second email',
              },
            ],
          })(
            <Div>
              <Span>Second Email Addresses</Span>
              <ReactMultiEmail
                placeholder=''
                emails={supplier?.secondEmailAddresses}
                onChange={_emails => {
                  setSupplier({ ...supplier, secondEmailAddresses: _emails })
                }}
                validateEmail={
                  email => isEmail(email) // return boolean
                }
                getLabel={(email, index, removeEmail) => (
                  <InnerDiv data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </InnerDiv>
                )}
              />
            </Div>,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('contact number', {
            rules: [
              {
                required: true,
                message: 'Please input a contact number',
              },
            ],
          })(
            <InputComp
              value={supplier.phoneNumber}
              onChange={e => {
                setSupplier({ ...supplier, phoneNumber: e.target.value })
              }}
              addonBefore={'Contact number'}
              placeholder='Contact number'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('integration type', {
            rules: [
              {
                message: 'Please input a integration type',
              },
            ],
          })(
            <InputComp
              value={supplier.integrationType}
              onChange={e => {
                setSupplier({ ...supplier, integrationType: e.target.value })
              }}
              addonBefore={'Integration type'}
              placeholder='Integration type'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('accountNumber', {
            rules: [
              {
                required: true,
                message: 'Please input the account number',
              },
            ],
          })(
            <InputComp
              value={supplier.accountNumber}
              onChange={e =>
                setSupplier({ ...supplier, accountNumber: e.target.value })
              }
              addonBefore={'OMS account number'}
              placeholder='OMS account number'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('minOrderValue', {
            rules: [
              {
                required: true,
                message: 'Please input the minimum order value',
              },
            ],
          })(
            <InputComp
              value={supplier.minOrderValue}
              onChange={e => {
                setSupplier({
                  ...supplier,
                  minOrderValue: parseInt(e.target.value, 10),
                })
              }}
              addonBefore={'Minimum order value'}
              placeholder='Minimum order value'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('orderCutOffTime', {
            rules: [
              {
                required: true,
                message: 'Please input order cut off time',
              },
            ],
          })(
            <InputComp
              value={supplier.orderCutOffTime}
              onChange={e => {
                setSupplier({ ...supplier, orderCutOffTime: e.target.value })
              }}
              addonBefore={'Order cut off time'}
              placeholder='Order cut off time'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('vendorCode', {
            rules: [
              {
                message: 'Please input vendor code',
              },
            ],
          })(
            <InputComp
              value={supplier.vendorCode}
              onChange={e => {
                setSupplier({ ...supplier, vendorCode: e.target.value })
              }}
              addonBefore={'Vendor code'}
              placeholder='Vendor code'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('logoUrl', {
            rules: [
              {
                required: true,
                message: "Please input the logo's URL",
              },
            ],
          })(
            <InputComp
              value={supplier.logoUrl}
              onChange={e => {
                setSupplier({ ...supplier, logoUrl: e.target.value })
              }}
              addonBefore={'Logo URL'}
              placeholder='Logo URL'
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('deliveryDays', {
            rules: [
              {
                required: true,
                message: 'Please input the delivery days',
              },
            ],
          })(
            <Select
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              placeholder='Delivery days'
              value={supplier.deliveryDays}
              onChange={value => {
                setSupplier({ ...supplier, deliveryDays: value })
              }}
            >
              <Select.Option key='mon'>Monday</Select.Option>
              <Select.Option key='tue'>Tuesday</Select.Option>
              <Select.Option key='wed'>Wednesday</Select.Option>
              <Select.Option key='thu'>Thursday</Select.Option>
              <Select.Option key='fri'>Friday</Select.Option>
              <Select.Option key='sat'>Saturday</Select.Option>
              <Select.Option key='sun'>Sunday</Select.Option>
            </Select>,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator(
            'dc',
            {},
          )(
            <InputComp
              value={supplier.dc}
              onChange={e => {
                setSupplier({ ...supplier, dc: e.target.value })
              }}
              addonBefore={'DC'}
              placeholder='Not Applicable'
            />,
          )}
        </FormItem>
        <FormItem name='title'>
          <Popconfirm
            title='Are you sure the inputs are correct?'
            placement='topRight'
            onConfirm={() => handleSubmit()}
            okText='Yes'
            cancelText='No'
          >
            <Button type='primary' htmlType='submit'>
              Add
            </Button>
          </Popconfirm>
        </FormItem>
      </CardComp>
    </Form>
  )
}

const CardComp = styled(Card)``

const InputComp = styled(Input)``

const FormItem = styled(Form.Item)`
  margin-bottom: 5px !important;
`
const InnerDiv = styled.div`
  background-color: ${({ theme }) => theme.colours.lettuceGreen} !important;
`
const Span = styled.span`
  padding: 0 10px;
  border: 1px solid ${({ theme }) => theme.colours.panSilver};
  background-color: ${({ theme }) => theme.colours.mayoWhite};
`
const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .react-multi-email {
    border: 1px solid ${({ theme }) => theme.colours.panSilver};
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .react-multi-email:hover,
  .react-multi-email.focused {
    border: 1px solid ${({ theme }) => theme.colours.lettuceGreen} !important;
  }
`
const WrappedSupplierCard = Form.create({ name: 'add_supplier' })(SupplierCard)

export default WrappedSupplierCard
