import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { AutoComplete, Button, Select, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchVenueInfo } from '../../features/VenueSlice'
import styled from 'styled-components/macro'
import dayjs from 'dayjs'
const { Option } = AutoComplete

const DownloadAllInfo = ({ venues }) => {
  const headersStockTakeValuation = [
    { key: 'firstCol' },
    { key: 'secondCol' },
    { key: 'thirdCol' },
    { key: 'fourthCol' },
    { key: 'fifthCol' },
    { key: 'sixthCol' },
    { key: 'seventhCol' },
    { key: 'eighthCol' },
    { key: 'ninthCol' },
  ]
  const dispatch = useDispatch()
  const venueInfo = useSelector(state => state.venue.venueInfo)
  const [dataOfReport, setDataOfReport] = useState([])
  const refToSelector = useRef()
  const [selectedVenueForDownload, setSelectedVenueForDownload] = useState(null)
  const [isSelectorOpened, setIsSelectorOpened] = useState(false)

  useEffect(() => {
    if (venueInfo !== null) {
      const suppliers = venueInfo.venueSuppliers.map(venueSupplier => {
        return { firstCol: venueSupplier.supplierTitle }
      })
      const products = venueInfo.venueProducts.map(venueProduct => {
        return {
          firstCol: venueProduct.title,
          secondCol: venueProduct.product?.size,
          thirdCol: venueProduct.product.UoM,
          fourthCol: venueProduct.supplierTitle,
          fifthCol: venueProduct.product.price,
          sixthCol: venueProduct.product.hasVat,
          seventhCol: venueProduct.product.sku,
        }
      })
      const orderedProducts = []
      venueInfo.venueOrders.forEach(order => {
        const products = order.products.map(prod => {
          return {
            firstCol: dayjs(order.orderedDate).format('YYYY-MM-DD HH:MM'),
            secondCol: prod.currentProduct.title,
            thirdCol: prod.currentProduct.size,
            fourthCol: prod.currentProduct.UoM,
            fifthCol: order.supplierTitles[0],
            sixthCol: prod.currentProduct.price,
            seventhCol: prod.currentProduct.hasVat,
            eighthCol: prod.currentProduct.sku,
            ninthCol: prod.orderedQuantity,
          }
        })
        orderedProducts.push(...products)
      })
      setDataOfReport([
        {
          firstCol: `Info about:`,
          secondCol: selectedVenueForDownload?.title,
        },
        {},
        { firstCol: 'Venue:' },
        {
          firstCol: `_id:`,
          secondCol: selectedVenueForDownload?._id,
        },
        {
          firstCol: `Title:`,
          secondCol: selectedVenueForDownload?.title,
        },
        {
          firstCol: `City:`,
          secondCol: selectedVenueForDownload?.addressCity,
        },
        {
          firstCol: `First address:`,
          secondCol: selectedVenueForDownload?.addressLine1,
        },
        {
          firstCol: `Second address:`,
          secondCol: selectedVenueForDownload?.addressLine2,
        },
        {
          firstCol: `Phone number:`,
          secondCol: selectedVenueForDownload?.phoneNumber,
        },
        {},
        {},
        { firstCol: `Suppliers:` },
        ...suppliers,
        {},
        {},
        { firstCol: 'Products:' },
        {
          firstCol: 'Title',
          secondCol: 'Size',
          thirdCol: 'UoM',
          fourthCol: 'Supplier',
          fifthCol: 'Price',
          sixthCol: 'Has_VAT',
          seventhCol: 'SKU',
        },
        ...products,
        {},
        {},
        { firstCol: 'Orders for last 30 days' },
        {
          firstCol: 'Ordered date',
          secondCol: 'Title',
          thirdCol: 'Size',
          fourthCol: 'UoM',
          fifthCol: 'Supplier',
          sixthCol: 'Price',
          seventhCol: 'Has_VAT',
          eighthCol: 'SKU',
          ninthCol: 'Ordered qty',
        },
        ...orderedProducts,
        {
          firstCol: 'Total ordered:',
          sixthCol: `${(
            venueInfo.venueOrders.reduce((sum, order) => {
              const totalByProducts = order.products.reduce((sum, product) => {
                return (
                  sum + product.currentProduct.price * product.orderedQuantity
                )
              }, 0)
              return sum + totalByProducts
            }, 0) / 100
          ).toFixed(2)}`,
        },
      ])
    }
  }, [venueInfo, selectedVenueForDownload])

  const handleOnChange = async value => {
    const selectedVenue = venues.find(venue => venue._id === value)
    setSelectedVenueForDownload(selectedVenue)
    await dispatch(fetchVenueInfo(value))
  }
  return (
    <StyledBlock>
      <div onClick={() => setIsSelectorOpened(!isSelectorOpened)}>
        <Select
          open={isSelectorOpened}
          showSearch
          placeholder={'Select a venue'}
          onChange={value => handleOnChange(value)}
          onFocus={() => setIsSelectorOpened(true)}
          onBlur={() => setIsSelectorOpened(false)}
          optionFilterProp='children'
          style={{ width: 200 }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          ref={refToSelector}
        >
          {venues.map(venue => (
            <Option value={venue._id} key={venue._id}>
              {venue.title}
            </Option>
          ))}
        </Select>
      </div>
      {selectedVenueForDownload && venueInfo ? (
        <Button style={{ marginRight: '10px' }}>
          <CSVLink
            data={dataOfReport}
            headers={headersStockTakeValuation}
            filename={`${selectedVenueForDownload?.title}-All-Info.csv`}
            disabled={!venueInfo || !selectedVenueForDownload}
          >
            Venue report CSV
          </CSVLink>
        </Button>
      ) : (
        <Tooltip title={'Firstly select venue'}>
          <Button onClick={() => refToSelector.current?.focus()}>
            Venue report CSV
          </Button>
        </Tooltip>
      )}
    </StyledBlock>
  )
}

const StyledBlock = styled.div`
  display: flex;
  gap: 10px;
`

export default DownloadAllInfo
