import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    accounts: [],
    accountVenues: [],
    isLoading: false,
  },
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload.accounts
    },
    setAccountVenues: (state, action) => {
      state.accountVenues = action.payload
    },
    editAccountInState: (state, action) => {
      const index = state.accounts.findIndex(
        account => account._id === action.payload._id,
      )
      if (index !== -1) state.accounts[index] = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setAccounts,
  setAccountVenues,
  editAccountInState,
  setIsLoading,
} = accountsSlice.actions

export const fetchAccounts = () => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const session = await Auth.currentAuthenticatedUser()
    const data = await API.get('api', `accounts`, {
      queryStringParameters: {
        username: session.attributes.sub,
      },
    })
    dispatch(setAccounts(data))
    dispatch(setIsLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setIsLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export const editAccount = account => async dispatch => {
  try {
    const session = await Auth.currentAuthenticatedUser()
    const accountFromServer = await API.post('api', `account`, {
      body: account,
      queryStringParameters: {
        username: session.attributes.sub,
      },
    })
    dispatch(editAccountInState(accountFromServer))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const fetchAccountVenues = data => async dispatch => {
  try {
    const { venues } = await API.get(
      'api',
      `venues/${data?.username}?accountApi=true`,
    )
    dispatch(setAccountVenues(venues))
  } catch (error) {
    console.log(error)
    Sentry.captureException(error)
  }
}
export const AssignEmailToVenue = async data => {
  try {
    const session = await Auth.currentAuthenticatedUser()
    const username = session.attributes.sub
    await API.post('api2', `admin/assignEmail?username=${username}`, {
      body: data,
    })
  } catch (error) {
    console.log(error)
    Sentry.captureException(error)
  }
}

export default accountsSlice
