import React, { useState } from 'react'
import styled from 'styled-components'
import OMSLogo from 'src/assets/images/OMSLogoWht.png'
import { Layout, Input, Button } from 'antd'
import { H4 } from 'src/branding/typography'
import { API } from 'aws-amplify'
import Loading from 'src/components/styled/Loading'

const DeleteDataPage = () => {
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState({
    venueTitle: '',
    venueCode: '',
    accountHolderName: '',
    accountEmail: '',
  })

  const handleInputChange = e => {
    const { name, value } = e.target
    setDetails({
      ...details,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    const response = await API.post('api2', `sendDeleteDataRequest`, {
      body: details,
    })
    setLoading(false)
    if (response.success) {
      setDetails({
        venueTitle: '',
        venueCode: '',
        accountHolderName: '',
        accountEmail: '',
      })
      alert('Delete Data request sent successfully')
    } else {
      alert('An error occured, please try agin')
    }
  }

  return (
    <Layout>
      <Header>
        <LeftHeader>
          <Logo src={OMSLogo} alt='' />
        </LeftHeader>
        <MidHeader>
          <VenueTitle>Delete Your Data From OMS</VenueTitle>
        </MidHeader>
      </Header>

      <Content>
        <IntroText>
          You may enter your details below to request the admin to delete your
          data.
          <br />
          The admin will review and process your request accordingly.
        </IntroText>

        <DeleteCard>
          <Input
            name='venueTitle'
            value={details.venueTitle}
            onChange={handleInputChange}
            placeholder='Venue Title'
            style={{
              width: '100%',
              padding: '20px',
              fontSize: '16px',
              margin: '10px 0',
            }}
          />
          <Input
            name='venueCode'
            value={details.venueCode}
            onChange={handleInputChange}
            placeholder='Venue Code'
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              margin: '10px 0',
            }}
          />
          <Input
            name='accountHolderName'
            value={details.accountHolderName}
            onChange={handleInputChange}
            placeholder='Account Holder Name'
            style={{
              width: '100%',
              padding: '20px',
              fontSize: '16px',
              margin: '10px 0',
            }}
          />
          <Input
            name='accountEmail'
            value={details.accountEmail}
            onChange={handleInputChange}
            placeholder='Account Email'
            style={{
              width: '100%',
              padding: '20px',
              fontSize: '16px',
              margin: '10px 0',
            }}
          />
          {loading ? (
            <Loading />
          ) : (
            <DeleteButton onClick={handleSubmit} type='primary'>
              Send Request
            </DeleteButton>
          )}
        </DeleteCard>
      </Content>
    </Layout>
  )
}

const Header = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 104px !important;
  background: ${({ theme }) => theme.colours.bbqBlack} !important;
  padding: 0 ${({ theme }) => theme.spacing.xxl} !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 150px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.md} !important;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 190px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
`

const Logo = styled.img`
  max-height: 100%;
  height: 80px;
  margin-top: 4px;
`

const LeftHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 82px;
`

const MidHeader = styled.div`
  display: flex;
  position: absolute;
  max-width: 268px;
  left: 50%;
  transform: translate(-50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 75%;
    position: relative;
    max-width: 100%;
    left: 0;
    transform: translate(0%);
    justify-content: center;
    padding-right: 16px;
  }
  @media (max-width: 1480px) and (min-width: 1291px) {
    left: 40vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: flex-end;
  }
`

const VenueTitle = styled(H4)`
  text-align: center;
  color: ${({ theme }) => theme.colours.lettuceGreen};
`

const Content = styled.div`
  padding: 32px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`

const IntroText = styled(H4)`
  text-align: center;
  color: #595959;
  margin-bottom: 24px;
`

const DeleteCard = styled.div`
  width: 100%;
  height: 500px;
  max-width: 500px;
  padding: 50px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`

const DeleteButton = styled(Button)`
  width: 100%;
  padding: 20px;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  &:hover {
    background: #40a9ff;
  }
`

export default DeleteDataPage
